<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2" v-if="id==2">
                <span class="p-float-label">
                <InputText v-model="device" > </InputText>
                <label for="device">Search</label>
              </span>
              </div>  &nbsp;
			  <div class="my-2"  v-if="id==2">
                <Button
                  icon="pi pi-search"
                  @click="get_list(),get_count()"
                  v-tooltip.bottom="'Search'"
                />
              </div> &nbsp;
						<div class="my-2">
							<Button v-if="id!=2" label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<!-- <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" /> -->
						</div>
					</template>

					<template v-slot:end>
						<FileUpload mode="basic" accept="application/pdf,image/jpeg,image/png" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>
				
				<DataTable :loading="loading" ref="dt" :lazy="true" :totalRecords="totalRecords" :paginator="true" :value="products" v-model:selection="selectedProducts" :dataKey="columns[0]"  :rows="limit" :filters="filters"
							@page="onPage($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25, totalRecords]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll" style="font-size: 12px"
          :rowHover="true" showGridlines>
		  <template #empty>
        <b style="text-align:center">No records found.</b>
		
      </template>
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Manage {{head[id-1]}}</h5>

							<Button icon="pi pi-refresh" @click="get_list(1),get_count(1)"   v-tooltip.bottom="'Refresh'"/>
							<!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
						</div>
					</template>
						<Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					    <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column>
					<Column headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>


			
					
					
				
				<Dialog v-model:visible="productDialog" :style="{width: '450px',width: '450px'}" header="Product Details" :modal="true" class="p-fluid">
					<!-- <img :src="'images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />
					<div class="field">
						<label for="name">Name</label>
						<InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
						<small class="p-invalid" v-if="submitted && !product.name">Name is required.</small>
					</div>
					<div class="field">
						<label for="description">Description</label>
						<Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
					</div>

					<div class="field">
						<label for="inventoryStatus" class="mb-3">Inventory Status</label>
						<Dropdown id="inventoryStatus" v-model="product.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
							<template #value="slotProps">
								<div v-if="slotProps.value && slotProps.value.value">
									<span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
								</div>
								<div v-else-if="slotProps.value && !slotProps.value.value">
									<span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
						</Dropdown>
					</div>

					<div class="field">
						<label class="mb-3">Category</label>
						<div class="formgrid grid">
							<div class="field-radiobutton col-6">
								<RadioButton id="category1" name="category" value="Accessories" v-model="product.category" />
								<label for="category1">Accessories</label>
							</div>
							<div class="field-radiobutton col-6">
								<RadioButton id="category2" name="category" value="Clothing" v-model="product.category" />
								<label for="category2">Clothing</label>
							</div>
							<div class="field-radiobutton col-6">
								<RadioButton id="category3" name="category" value="Electronics" v-model="product.category" />
								<label for="category3">Electronics</label>
							</div>
							<div class="field-radiobutton col-6">
								<RadioButton id="category4" name="category" value="Fitness" v-model="product.category" />
								<label for="category4">Fitness</label>
							</div>
						</div>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="price">Price</label>
							<InputNumber id="price" v-model="product.price" mode="currency" currency="USD" locale="en-US" />
						</div>
						<div class="field col">
							<label for="quantity">Quantity</label>
							<InputNumber id="quantity" v-model="product.quantity" integeronly />
						</div>
					</div> -->
					<FormDataUsagesMaster  :product_prop="product" @submit="saveProduct1"></FormDataUsagesMaster>
					<!-- <template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
					</template> -->
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import ProductService from '../service/ProductService';
import FormDataUsagesMaster from "@/components/FormDataUsagesMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";

export default {
	data() {
		return {
			device:"",
			page_no:1,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			lazyParams:{},
			product: {},
			selectedProducts: null,
			filters: {},
			offset:1,
			loading: false,
			limit:10,
			columns:[],
			head:[
				"Data Usages",
				"Network Connectivity",
				"Students",
				"Teachers",
				"Student Registration",
				"News Master",
				"Government Panel",
				"Feedback Master",
				"User Queries",
				"Schemes Master",
				"Notice Master",
				"Subject Master",
				"Teacher Schedule Master",
				"Teacher Schedule DE Master",
				"Query Types Master",
				"Sub Query Types Master",
				"Admin Users",
				"Application Roles",
				"Departments",
				"Cast Master",
				"Year Master",
				"District Master",
				"Student Attendance",
				"Student Document",
				"Teachers",
				"Radical ids",
				"Subject Topics",
				"Query Comments",
				"Student Submit Answers",
				"Mock Tests",
				"Mock Test Papers",
				"Mock Test Results",
				"Video"
				
			],
			pageno:1,
			submitted: false,
			totalRecords:0,
			id:'',
			statuses: [
				{label: 'INSTOCK', value: 'instock'},
				{label: 'LOWSTOCK', value: 'lowstock'},
				{label: 'OUTOFSTOCK', value: 'outofstock'}
			]
		}
	},
	productService: null,
	async created() {

		this.productService = new ProductService();
		this.initFilters();
		
	},
	async mounted() {
		// this.productService.getProducts().then(data => this.products = data);
				this.id= this.$route.params.id;
		
		 this.lazyParams = {
            first: 0,
            rows: this.limit,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };
		if(this.totalRecords==0)
		{
			await this.get_count();
		}
		await this.get_list();
		//alert(this.id);
	},
	watch:{
		    async $route() {
				this.products=[];
     this.columns=[];
	 this.totalRecords=0;
	 this.id= this.$route.params.id; 
	 if(this.id)
	 {
		await this.get_count();
		this.get_list();
	 }
    }

	},
	methods: {
		async onPage(event) {
            this.lazyParams = event;
			this.page_no=event.page+1;
			this.limit=event.rows;
			// console.log(event);
			await this.get_count();
			if(this.totalRecords>0)
			{
            	this.get_list();
			}
        },
		 get_count: function(temp) {
			temp?this.device='':'';
			var data = {		
    		"count":true
			};
			if (this.device)
			{
				data["searchtext"] = this.device;
			}
			this.loading=true;
	  var promise = apis.list(data,this.id);
      promise
        .then(response => {
			
			this.loading=false;
			console.log(response);
			this.totalRecords=response.data;
			//alert(this.totalRecords);
		});
		
			console.log(data);
			
		},
		get_list: function(temp) {
			temp?this.device='':'';
			var data = {		
    		"Limit":this.limit,
    		"page_no":this.page_no,
    		"count":false,
			
			};
			if (this.device)
			{
				data["searchtext"] = this.device;
			}
			this.loading=true;
	  var promise = apis.list(data,this.id);
      promise
        .then(response => {
			this.loading=false;
			console.log(response);
			this.products=response.data;
			console.log(this.products[0]);
			if(this.columns.length==0)
			{
				if(this.products.length>0)
				{
					this.columns= Object.keys(this.products[0]);
				}
			}
		});
		
			console.log(data);
			
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		saveProduct1() {alert(this.product);},
		saveProduct() {
			this.submitted = true;
			if (this.product.name.trim()) {
			if (this.product.id) {
				this.product.inventoryStatus = this.product.inventoryStatus.value ? this.product.inventoryStatus.value: this.product.inventoryStatus;
				this.products[this.findIndexById(this.product.id)] = this.product;
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
				}
				else {
					this.product.id = this.createId();
					this.product.code = this.createId();
					this.product.image = 'product-placeholder.svg';
					this.product.inventoryStatus = this.product.inventoryStatus ? this.product.inventoryStatus.value : 'INSTOCK';
					this.products.push(this.product);
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Created', life: 3000});
				}
				this.productDialog = false;
				this.product = {};
			}
		},
		editProduct(product) {
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
			this.products = this.products.filter(val => val.id !== this.product.id);
			this.deleteProductDialog = false;
			this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			this.deleteProductsDialog = false;
			this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	},
	components:{FormDataUsagesMaster}
		
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
