<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-12">
        <!-- <div class="field">
          <img
            :src="'images/product/shoes.jpg'"
            :alt="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          />
        </div> -->
        <div class="field">
          <label for="name">Device ID</label>
          <InputText
            id="name"
            v-model.trim="product.devicE_ID"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.devicE_ID }"
          />
          <small class="p-invalid" v-if="submitted && !product.devicE_ID"
            >Name is required.</small
          >
        </div>
        <div class="field">
          <label for="data_usage_gb">Data Usage(GB)</label>
          <InputText
            id="data_usage_gb"
            v-model.trim="product.datA_USAGE_GB"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.datA_USAGE_GB }"
          />
        </div>
        <div class="field">
          <label for="data_usage_wifi">Data Usage(Wifi)</label>
          <InputText
            id="data_usage_wifi"
            v-model.trim="product.datA_USAGE_WIFI"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.datA_USAGE_WIFI }"
          />
        </div>
        <div class="field">
          <label for="datA_USAGE_MOBILE">Data Usage(Mobile)</label>
          <InputText
            id="datA_USAGE_MOBILE"
            v-model.trim="product.datA_USAGE_MOBILE"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !product.datA_USAGE_MOBILE }"
          />
        </div>
      </div>
      <div class="col-12 md:col-6">
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-success mr-1 mb-1"
          @click="onSubmit"
        />
      </div>
      <div class="col-12 md:col-6">
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-danger mr-1 mb-1"
          @click="onReset"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product_prop"],
  data() {
    return {
      product: this.product_prop,
      submitted: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.$emit("submit");
    },
    onReset(evt) {
      evt.preventDefault();
     
      this.product = {
        devicE_ID: ""
        
        
      };
  },
  },
  mounted() {
    // alert(this.product);
    console.log(this.product);
  },
};
</script>

<style>
</style>